import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '@fortawesome/fontawesome-free/css/all.min.css';

export default function Footer() {
    const [hover, setHover] = useState(false);
    return (
        <>
            {/* inquery form */}
            <div
                className={`inquiry-form-container ${hover ? 'hovered' : ''}`}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <div className="inquiry-form-trigger">
                    Inquiry  <i className="fa fa-question-circle" aria-hidden="true"></i>
                </div>
                <div className="inquiry-form">
                    <h3 className='text-center text-light mb-3'>Inquiry Form</h3>
                    <form method='' action='?'>
                        <div className="form-floating form-floating-outline">
                            <input type="text" className="form-control" placeholder="Name" />
                            <label htmlFor="floatingInput">Name</label>
                        </div>
                        <div className="form-floating form-floating-outline">
                            <input type="email" className="form-control" placeholder="name@example.com" />
                            <label htmlFor="floatingInput">Email address</label>
                        </div>
                        <div className="form-floating form-floating-outline">
                            <input type="number" className="form-control" placeholder="Mobile" required />
                            <label htmlFor="floatingInput">Mobile</label>
                        </div>
                        <div className="form-floating form-floating-outline">
                            <textarea placeholder="Your Message" className='form-control' ></textarea>
                            <label htmlFor="floatingInput">Your Message</label>
                        </div>
                        <button type="submit">Send</button>
                    </form>
                </div>
            </div>
            {/* inquery form // */}

            {/* footer block */}
            <footer className="w3l-footer-29-main">
                <div className="footer-29 pt-5 pb-4">
                    <div className="container pt-md-4">
                        <div className="row footer-top-29" data-aos="fade-up">
                            <div className="col-lg-3 col-md-3 col-8 footer-list-29 text-md-start">
                                <h6 className="footer-title-29">Contact Info</h6>
                                <p className="mb-2 pe-xl-5">
                                    Address: 3rd Floor, Above NBC Boring Chauraha, Jhotwara, Jaipur
                                </p>
                                <p className="mb-2">
                                    Phone Number: <a href="tel:+917733095696">+917733095696</a>
                                </p>
                                <p className="mb-2">
                                    Email: <a href="mailto:contact@arjtechnologies.com">contact@arjtechnologies.com</a>
                                </p>
                                {/* <p className="mb-2">
                                    Support: <a href="mailto:support@example.com">support@example.com</a>
                                </p> */}
                            </div>

                            <div className="col-lg-3 col-md-3 col-4 footer-list-29 mt-md-0 mt-4 ">
                                <h6 className="footer-title-29">Our Services</h6>
                                <ul>
                                    <li><Link to="/Services">Software Development</Link></li>
                                    <li><Link to="/Services">Web Development</Link></li>
                                    <li><Link to="/Services">App Development</Link></li>
                                    <li><Link to="/Services">Web Design</Link></li>
                                    <li><Link to="/">UI/UX Design Service</Link></li>
                                    <li><Link to="/Services">Hosting & Domain Service</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-2 col-4 footer-list-29 mt-md-0 mt-4 ">
                                <h6 className="footer-title-29">Quick Links</h6>
                                <ul>
                                    <li><Link to="/About">About Company</Link></li>
                                    <li><Link to="/About">About Our Team</Link></li>
                                    <li><Link to="/Services">Our Services</Link></li>
                                    <li><Link to="/Contact">Contact Us</Link></li>
                                    <li><Link to="/">Our Product</Link></li>
                                    <li><Link to="/Portfolio">Our Portfolio</Link></li>
                                    <li><Link to="/privacy-policy">Privacy-Policy</Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 footer-list-29 mt-lg-0 mt-4 ps-lg-5 text-center text-md-start">
                                <h6 className="footer-title-29">Subscribe</h6>
                                <form action="#" className="subscribe d-flex justify-content-center justify-content-md-start" method="">
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email Address"
                                        required=""
                                        className="me-2"
                                    />
                                    <button className="button-style">
                                        <span className="fa fa-paper-plane" aria-hidden="true" />
                                    </button>
                                </form>
                                <p className="mt-4">
                                    Subscribe to our mailing list and get updates to your email inbox.
                                </p>
                            </div>
                        </div>
                        {/* copyright */}
                        <p className="copy-footer-29 text-center mt-5 pb-2">
                            © 2024 ARJ. All rights reserved. Developed by{" "}
                            <a href="/" target="_blank">
                                ||  ARJ Technologies
                            </a>
                        </p>
                        {/* //copyright */}
                    </div>
                </div>
            </footer>

            {/* //footer block */}
            {/* Js scripts */}
            {/* move top */}
            <button onclick="topFunction()" id="movetop" title="Go to top">
                <span className="fas fa-level-up-alt" aria-hidden="true" />
            </button>
            <section className="whatsapp_icon">
                <a href="https://wa.me/917733095696" target="blank">
                    <img src="assets/img/whats_app.png" alt="Awesome Image" />
                </a>
            </section>

        </>

    )
}
