import React from 'react'
import Header from '../main/Header'
import Footer from '../main/Footer'
import { Link } from 'react-router-dom'

export default function Services() {
    return (
        <>
            <Header />
            {/* Services banner */}
            <section className="w3l-about-breadcrumb text-center">
                <div className="breadcrumb-bg breadcrumb-bg-about3 py-5">

                </div>
            </section>
            {/* //Services banner */}
            <div className="service-section py-5">
                <div className="container py-lg-5 py-md-4 py-2">
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-sm-6 item">
                            <div className="card" data-aos="flip-down">
                                <div className="card-header p-0 position-relative border-0">
                                    <Link to={"/services"}>
                                        <img
                                            className="d-block img-responsive"
                                            src="assets/images/s1.jpg"
                                            alt="card-image"
                                        />
                                    </Link>
                                </div>
                                <div className="card-body service-details">
                                    <Link to={"/services"} className="service-heading">
                                        Quality Control System
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mt-sm-0 mt-4 pt-sm-0 pt-2">
                            <div className="card" data-aos="flip-down">
                                <div className="card-header p-0 position-relative border-0">
                                    <Link to={"/services"}>
                                        <img
                                            className="d-block img-responsive"
                                            src="assets/images/s2.jpg"
                                            alt="card-image"
                                        />
                                    </Link>
                                </div>
                                <div className="card-body service-details">
                                    <Link to={"/services"} className="service-heading">
                                        Professional Achievement
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mt-lg-0 mt-4 pt-lg-0 pt-2">
                            <div className="card" data-aos="flip-down">
                                <div className="card-header p-0 position-relative border-0">
                                    <Link to={"/services"}>
                                        <img
                                            className="d-block img-responsive"
                                            src="assets/images/s3.jpg"
                                            alt="card-image"
                                        />
                                    </Link>
                                </div>
                                <div className="card-body service-details">
                                    <Link to={"/services"} className="service-heading">
                                        Mobile Apps Design
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mt-lg-0 mt-4 pt-lg-0 pt-2">
                            <div className="card" data-aos="flip-down">
                                <div className="card-header p-0 position-relative border-0">
                                    <Link hto={"/services"}>
                                        <img
                                            className="d-block img-responsive"
                                            src="assets/images/s4.jpg"
                                            alt="card-image"
                                        />
                                    </Link>
                                </div>
                                <div className="card-body service-details">
                                    <Link to={"/services"} className="service-heading">
                                        Social Media &amp; Strategy
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Services Section */}
            <section className="about-section py-5">
                <div className="container py-lg-5 py-4">
                    <div className="title-heading-w3 mx-auto text-center mb-5 pb-xl-4">
                        <h3 className="w3l-title-main" data-aos="flip-right">
                            We run all kinds of IT services that vow your success
                        </h3>
                    </div>
                    <div className="row justify-content-center">
                        <div className=" col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1" data-aos="flip-right">
                                <div className="img">
                                    <img src="assets/img/icon-3d-11.webp" alt="" className='w-100' />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">Soft. Development</a>
                                    </h5>
                                    <div className="text-center">
                                        <button className='btn btn-primary rounded-pill'> Read More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1" data-aos="flip-right">
                                <div className="img">
                                    <img src="assets/img/icon-3d-4.webp" alt="" className='w-100' />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">Web Development</a>
                                    </h5>
                                    <div className="text-center">
                                        <button className='btn btn-primary rounded-pill'> Read More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1" data-aos="flip-right">
                                <div className="img">
                                    <img src="assets/img/icon-3d-5.webp" alt="" className='w-100' />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url"> App Development</a>
                                    </h5>
                                    <div className="text-center">
                                        <button className='btn btn-primary rounded-pill'> Read More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1" data-aos="flip-right">
                                <div className="img">
                                    <img src="assets/img/desgin1.png" alt="" className='w-100' />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">Web Design</a>
                                    </h5>
                                    <div className="text-center">
                                        <button className='btn btn-primary rounded-pill'> Read More</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row justify-content-center">
                        <div className=" col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1" data-aos="flip-left">
                                <div className="img">
                                    <img src="assets/img/icon-3d-3.webp" alt="" className='w-100' />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">UI/UX Design Service</a>
                                    </h5>
                                    <div className="text-center">
                                        <button className='btn btn-primary rounded-pill'> Read More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1" data-aos="flip-left">
                                <div className="img">
                                    <img src="assets/img/graphic.png" alt="" className='w-100' />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">Graphic Design</a>
                                    </h5>
                                    <div className="text-center">
                                        <button className='btn btn-primary rounded-pill'> Read More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1" data-aos="flip-left">
                                <div className="img">
                                    <img src="assets/img/security.png" alt="" className='w-100' />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">Hosting & Domain Service
                                        </a>
                                    </h5>
                                    <div className="text-center">
                                        <button className='btn btn-primary rounded-pill'> Read More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
                            <div className="about-single card1" data-aos="flip-left">
                                <div className="img">
                                    <img src="assets/img/server.png" alt="" className='w-100' />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">Systems Management</a>
                                    </h5>
                                    <div className="text-center">
                                        <button className='btn btn-primary rounded-pill'> Read More</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* //Services Section */}
            {/* technology Section */}
            <section className="new_tech_sec pb-5">
                <div className="container">
                    <h1 className='text-center mb-5' data-aos="fade-up">Our Latest Technologies</h1>
                    <ul className="nav nav-tabs mb-5" id="myTab" role="tablist" data-aos="fade-up">
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active"
                                id="home-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#All"
                                type="button"
                                role="tab"
                                aria-controls="home-tab-pane"
                                aria-selected="true"
                            >
                                Custom
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="profile-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#App"
                                type="button"
                                role="tab"
                                aria-controls="profile-tab-pane"
                                aria-selected="false"
                            >
                                App Development
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="contact-tab-pane"
                                data-bs-toggle="tab"
                                data-bs-target="#Web"
                                type="button"
                                role="tab"
                                aria-controls="contact-tab-pane"
                                aria-selected="false"
                            >
                                Web Development
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="Cms-tab-pane"
                                data-bs-toggle="tab"
                                data-bs-target="#Cms"
                                type="button"
                                role="tab"
                                aria-controls="contact-tab-pane"
                                aria-selected="false"
                            >
                                CMS
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="contact-tab-pane"
                                data-bs-toggle="tab"
                                data-bs-target="#DataBase"
                                type="button"
                                role="tab"
                                aria-controls="contact-tab-pane"
                                aria-selected="false"
                            >
                                DataBase
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="contact-tab-pane"
                                data-bs-toggle="tab"
                                data-bs-target="#FrontEnd"
                                type="button"
                                role="tab"
                                aria-controls="contact-tab-pane"
                                aria-selected="false"
                            >
                                Frontend Development
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent" data-aos="fade-up">
                        <div
                            className="tab-pane fade show active"
                            id="All"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                            tabIndex={0}
                        >
                            <div className="row mt-3 mb-3">
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Swift.svg" alt="" width={65} />
                                        <p className='fw-bold'>Swift</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/SwiftUI.svg" alt="" width={65} />
                                        <p className='fw-bold'>Swift UI</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Kotlin.svg" alt="" width={65} />
                                        <p className='fw-bold'>Kotlin</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Java.svg" alt="" width={65} />
                                        <p className='fw-bold'>Java</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/React-native.svg" alt="" width={65} />
                                        <p className='fw-bold'>React Native</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/images/Flutter.svg" alt="" width={65} />
                                        <p className='fw-bold'>Flutter</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3 mb-3">
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Angular.svg" alt="" width={65} />
                                        <p className='fw-bold'>Angular</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/images/React.svg" alt="" width={65} />
                                        <p className='fw-bold'>React</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Javascript.svg" alt="" width={65} />
                                        <p className='fw-bold'>Javascript</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Bootstrap.svg" alt="" width={65} />
                                        <p className='fw-bold'>Bootstrap</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/CSS3.svg" alt="" width={65} />
                                        <p className='fw-bold'>CSS</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/HTML5.svg" alt="" width={65} />
                                        <p className='fw-bold'>HTML</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3 mb-3">
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/img/node-js.909x1024.png" alt="" width={55} />
                                        <p className='fw-bold'>NODE JS</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/img/python_1822899.png" alt="" width={65} />
                                        <p className='fw-bold'>PYTHON</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/img/js_5968292.png" alt="" width={65} />
                                        <p className='fw-bold'>JAVASCRIPT</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/img/php.png" alt="" width={65} />
                                        <p className='fw-bold'>PHP</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/img/laravel.995x1024.png" alt="" width={65} />
                                        <p className='fw-bold'>LARAVEL</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/oracle.png" alt="" width={65} />
                                        <p className='fw-bold'>Oracle</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3 mb-3">
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/wordpress.svg" alt="" width={65} />
                                        <p className='fw-bold'>WordPress</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/images/Strapi.svg" alt="" width={65} />
                                        <p className='fw-bold'>Strap.io</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Sitecore.svg" alt="" width={65} />
                                        <p className='fw-bold'>Sitecore</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/webflow.svg" alt="" width={65} />
                                        <p className='fw-bold'>Webflow</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/MySQL.svg" alt="" width={65} />
                                        <p className='fw-bold'>MySQL</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/images/MongoDB.svg" alt="" width={65} />
                                        <p className='fw-bold'>MongoDB</p>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div
                            className="tab-pane fade"
                            id="App"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                            tabIndex={0}
                        >
                            <div className="row mt-3 mb-3">
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Swift.svg" alt="" width={65} />
                                        <p className='fw-bold'>Swift</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/SwiftUI.svg" alt="" width={65} />
                                        <p className='fw-bold'>Swift UI</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Kotlin.svg" alt="" width={65} />
                                        <p className='fw-bold'>Kotlin</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Java.svg" alt="" width={65} />
                                        <p className='fw-bold'>Java</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/React-native.svg" alt="" width={65} />
                                        <p className='fw-bold'>React Native</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/images/Flutter.svg" alt="" width={65} />
                                        <p className='fw-bold'>Flutter</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div
                            className="tab-pane fade"
                            id="Web"
                            role="tabpanel"
                            aria-labelledby="contact-tab"
                            tabIndex={0}
                        >
                            <div className="row mt-3 mb-3">
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/img/node-js.909x1024.png" alt="" width={55} />
                                        <p className='fw-bold'>NODE JS</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/img/python_1822899.png" alt="" width={65} />
                                        <p className='fw-bold'>PYTHON</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/img/js_5968292.png" alt="" width={65} />
                                        <p className='fw-bold'>JAVASCRIPT</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/img/php.png" alt="" width={65} />
                                        <p className='fw-bold'>PHP</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/img/laravel.995x1024.png" alt="" width={65} />
                                        <p className='fw-bold'>LARAVEL</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="Cms"
                            role="tabpanel"
                            aria-labelledby="contact-tab"
                            tabIndex={0}
                        >
                            <div className="row mt-3 mb-3">
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/wordpress.svg" alt="" width={65} />
                                        <p className='fw-bold'>WordPress</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/images/Strapi.svg" alt="" width={65} />
                                        <p className='fw-bold'>Strap.io</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Sitecore.svg" alt="" width={65} />
                                        <p className='fw-bold'>Sitecore</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/webflow.svg" alt="" width={65} />
                                        <p className='fw-bold'>Webflow</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Umbraco.svg" alt="" width={65} />
                                        <p className='fw-bold'>Umbraco</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="DataBase"
                            role="tabpanel"
                            aria-labelledby="contact-tab"
                            tabIndex={0}
                        >
                            <div className="row mt-3 mb-3">
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/MySQL.svg" alt="" width={65} />
                                        <p className='fw-bold'>MySQL</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/images/MongoDB.svg" alt="" width={65} />
                                        <p className='fw-bold'>MongoDB</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/nosql.svg" alt="" width={65} />
                                        <p className='fw-bold'>NO SQL</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/oracle.png" alt="" width={65} />
                                        <p className='fw-bold'>Oracle</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="FrontEnd"
                            role="tabpanel"
                            aria-labelledby="contact-tab"
                            tabIndex={0}
                        >
                            <div className="row mt-3 mb-3">
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Angular.svg" alt="" width={65} />
                                        <p className='fw-bold'>Angular</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/images/React.svg" alt="" width={65} />
                                        <p className='fw-bold'>React</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Javascript.svg" alt="" width={65} />
                                        <p className='fw-bold'>Javascript</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Bootstrap.svg" alt="" width={65} />
                                        <p className='fw-bold'>Bootstrap</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/CSS3.svg" alt="" width={65} />
                                        <p className='fw-bold'>CSS</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/HTML5.svg" alt="" width={65} />
                                        <p className='fw-bold'>HTML</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            {/* //technology Section */}
            <Footer />
        </>

    )
}
