import React, { useEffect, useState } from 'react'
import Header from '../main/Header'
import Footer from '../main/Footer'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
export default function Home() {
    const [isModalVisible, setIsModalVisible] = useState(false);

    // Show modal after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsModalVisible(true);
        }, 5000); // 5000ms = 5 seconds

        return () => clearTimeout(timer); // Cleanup timer
    }, []);

    const closeModal = () => {
        setIsModalVisible(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        Swal.fire({
            title: "Inquiry Successful!",
            text: "😊 Thank you for Form Submitted . We will contact you shortly.",
            icon: "success",
            confirmButtonText: "OK",
        }).then(() => {
            closeModal();
        });
    };

    return (
        <>
            <Header />
            {/* banner section */}
            <section id="home" className="w3l-banner area">
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    {/*  */}

                </ul>
            </section>

            {/* demo modal */}
            {isModalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>
                            &times;
                        </span>
                        <h2>Inquiry Form</h2>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                placeholder="Your Name" className='form-control'
                                required
                            />
                            <input
                                type="email"
                                placeholder="Your Email" className='form-control'
                                required
                            />
                            <input
                                type="number"
                                placeholder="Your Contact Number" className='form-control'
                                required
                            />
                            <button type="submit" className='btn btn-dark'>Submit</button>
                        </form>
                    </div>
                </div>
            )}

            {/* demo modal */}

            {/* About Section */}
            <section className="w3l-about py-5" id="about">
                <div className="container py-md-5 py-2">
                    <div className="row w3l-row align-items-center">
                        <div className="col-lg-6 w3l-image pe-lg-5 mb-lg-0 mb-md-5 mb-4" data-aos="zoom-in">
                            <img src="assets/img/security2.png" alt="" className="img-fluid vert-move" width={450} />
                        </div>
                        <div className="col-lg-6 w3l-about-info px-lg-4 text-lg-start text-center" data-aos="fade-up">
                            <h6 className="w3l-title-small mb-1">About Us</h6>
                            <h3 className="w3l-title-main mb-2">
                                We provide the best IT Services
                            </h3>
                            <p className="mt-2 pe-lg-5">
                                At ARJ Technologies, we provide top-notch IT services to help businesses succeed in the digital era. Our expertise covers custom software, web and mobile app development, design, hosting, cloud solutions, and cybersecurity. We also offer IT consulting to support your technological needs.
                            </p>
                            <div className="w3l-btn">
                                <Link
                                    to={"/About"}
                                    className="btn btn-style btn-secondary mt-lg-3 mt-0 me-2"
                                >
                                    Read More
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* //About Section*/}
            {/* Services Section */}
            <section className="about-section py-5">
                <div className="container py-lg-5 py-md-4 py-2">
                    <div className="title-heading-w3 mx-auto text-center mb-sm-5 mb-4 pb-xl-4">
                        <h3 className="w3l-title-main" data-aos="flip-up">
                            We run all kinds of IT services that vow your success
                        </h3>
                    </div>
                    <div className="row justify-content-center">
                        {/* First Card */}
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center" data-aos="flip-left">
                            <div className="about-single card1">
                                <div className="img">
                                    <img src="assets/img/icon-3d-11.webp" alt="" className="w-100" />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">Soft. Development</a>
                                    </h5>
                                    <button className="btn btn-primary rounded-pill">Read More</button>
                                </div>
                            </div>
                        </div>
                        {/* Second Card */}
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center" data-aos="flip-right">
                            <div className="about-single card1">
                                <div className="img">
                                    <img src="assets/img/icon-3d-4.webp" alt="" className="w-100" />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">Web Development</a>
                                    </h5>
                                    <button className="btn btn-primary rounded-pill">Read More</button>
                                </div>
                            </div>
                        </div>
                        {/* Third Card */}
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center" data-aos="flip-left">
                            <div className="about-single card1">
                                <div className="img">
                                    <img src="assets/img/icon-3d-5.webp" alt="" className="w-100" />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">App Development</a>
                                    </h5>
                                    <button className="btn btn-primary rounded-pill">Read More</button>
                                </div>
                            </div>
                        </div>
                        {/* Fourth Card */}
                        <div className="col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center" data-aos="flip-right">
                            <div className="about-single card1">
                                <div className="img">
                                    <img src="assets/img/desgin1.png" alt="" className="w-100" />
                                </div>
                                <div className="about-content text-center">
                                    <h5 className="mb-3">
                                        <a href="#url">Web Design</a>
                                    </h5>
                                    <button className="btn btn-primary rounded-pill">Read More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* //Services Section */}
            {/* Product Section */}
            <section className='our_product py-5'>
                <div className="container">
                    <h1 className='text-center text-light py-3' data-aos="fade-up">Our Product</h1>
                    <p data-aos="fade-up" className='text-center text-light'>"Transform your operations with our integrated software solutions: <br /> streamline School Management, simplify Finance Management, optimize Garments Management, and <br />enhance E-commerce Management—all in one platform."</p>
                    <div className="row mt-5">
                        <div className="col-md-3 mt-3 " data-aos="flip-left" >
                            <div className="card d-flex justify-content-center align-item-center p-4">

                                <a href="https://scai.arjtechnologies.com/" target='_blank'>
                                    <img src="assets/img/school_software.gif" alt="" width={300} className='rounded-pill img-fluid' />
                                    <h3 className='text-center'>SCAI | School Management AI Software</h3>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 mt-3" data-aos="flip-up">
                            <div className="card d-flex justify-content-center align-item-center p-4">
                                <img src="assets/img/Garument_software.gif" alt="" width={300} className='rounded-pill img-fluid' />
                                <h3 className='text-center'>Garment Management Software</h3>
                            </div>
                        </div>
                        <div className="col-md-3  mt-3" data-aos="flip-up">
                            <div className="card d-flex justify-content-center align-item-center p-4">
                                <img src="assets/img/ecom.gif" alt="" width={300} className='rounded-pill img-fluid' />
                                <h3 className='text-center'>E-Commerce Management Software</h3>
                            </div>
                        </div>
                        <div className="col-md-3  mt-3" data-aos="flip-right">
                            <div className="card d-flex justify-content-center align-item-center p-4">
                                <img src="assets/img/finance.gif" alt="" width={300} className='rounded-pill img-fluid' />
                                <h3 className='text-center'>Finance Management Software</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Product Section // */}
            {/* Why Choose Us Section*/}
            <section className="w3l-whychooseus py-5" id="about1">
                <div className="container py-lg-5 py-md-4 py-2">
                    <h3 className="w3l-title-main mb-5 text-center" data-aos="fade-up">
                        The Creative Features, we provide <br /> to our clients
                    </h3>
                    <div className="row align-items-center">
                        <div className="col-lg-7 pe-lg-5 align-center">
                            <h1 className="w3l-title-main1 mb-5" data-aos="fade-up">Our Features</h1>

                            <p className="mt-3" data-aos="fade-up">
                                "Our product is designed to streamline your operations and boost efficiency, empowering your business to reach new heights. With innovative features and seamless integration, we provide the tools you need to succeed in today's competitive market."
                            </p>
                            <div className="row two-grids mt-5">
                                <div className="col-sm-6 grids_info d-flex" data-aos="zoom-out">
                                    <i className="fas fa-chalkboard-teacher" />
                                    <div className="detail ms-3">
                                        <h4 className="w3l-subtitle">Online Service</h4>
                                        <p>
                                            Our team ensures seamless integration and management of online platforms for optimal performance.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-6 grids_info d-flex mt-sm-0 mt-4" data-aos="zoom-out">
                                    <i className="fas fa-lightbulb" />
                                    <div className="detail ms-3">
                                        <h4 className="w3l-subtitle"> Creative Idea</h4>
                                        <p>
                                            Transform ideas into impactful digital experiences that captivate and inspire.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-6 grids_info d-flex mt-md-5 mt-4" data-aos="zoom-out">
                                    <i className="fas fa-headset" />
                                    <div className="detail ms-3">
                                        <h4 className="w3l-subtitle">Unlimited Support</h4>
                                        <p>
                                            Our dedicated team is available 24/7 to address any issues and keep your operations uninterrupted.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-6 grids_info d-flex mt-md-5 mt-4" data-aos="zoom-out">
                                    <i className="fab fa-servicestack" />
                                    <div className="detail ms-3">
                                        <h4 className="w3l-subtitle">Digital Services</h4>
                                        <p>
                                            We help businesses leverage technology to enhance their digital footprint and achieve growth.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 mt-lg-0 mt-5 position-relative" data-aos="zoom-in-left">
                            {/* <img src="assets/images/about1.jpg" alt="" className="img-fluid" /> */}
                            <img src="assets/img/benifit-img.png" alt="" className="img-fluid " />
                            <img src="assets/img/Arj_new_logo.png" className='tp-benifit-right-shape-1' alt="" />
                        </div>
                    </div>
                </div>
            </section>
            {/* //Why Choose Us Section*/}
            <section className='why_arj'>
                <div className="container">
                    <h4 className="mb-2 text-center" data-aos="fade-up">Why ARJ TECHNOLOGIES ?</h4>
                    <h6 className="mb-2 text-center" data-aos="fade-up">We are best at what we do</h6>
                    <div className="row mt-4">
                        <div className=" col-md-4 col-6 mt-2">
                            <div className="card card-body arj_card" data-aos="zoom-out">
                                <div className="div">
                                    <h5 className='text-center' data-aos="flip-left">Tailored Solutions
                                    </h5>
                                </div>
                                <p data-aos="fade-up">We design custom software and apps that align perfectly with your business requirements, ensuring functionality and efficiency.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2">
                            <div className="card card-body arj_card" data-aos="zoom-out">
                                <div className="div">
                                    <h5 className='text-center' data-aos="flip-left">End-to-End Expertise
                                    </h5>
                                </div>
                                <p data-aos="fade-up">From web design and development to hosting, cloud solutions, and IT consulting, we offer a comprehensive suite of services under one roof.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2">
                            <div className="card card-body arj_card" data-aos="zoom-out">
                                <div className="div">
                                    <h5 className='text-center' data-aos="flip-left">Industry-Specific Tools
                                    </h5>
                                </div>
                                <p data-aos="fade-up">Our specialized software, including school management systems and clothing showroom solutions, simplifies operations and boosts productivity.
                                </p>
                            </div>
                        </div>

                        <div className=" col-md-4 col-6 mt-2">
                            <div className="card card-body arj_card" data-aos="zoom-out">
                                <div className="div">
                                    <h5 className='text-center' data-aos="flip-left">Innovation-Driven
                                    </h5>
                                </div>
                                <p data-aos="fade-up">We leverage the latest technologies to deliver modern, scalable, and future-proof solutions for your business.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2">
                            <div className="card card-body arj_card" data-aos="zoom-out">
                                <div className="div">
                                    <h5 className='text-center' data-aos="flip-left">Strong Support
                                    </h5>
                                </div>
                                <p data-aos="fade-up">Our dedicated team ensures reliable post-deployment support and maintenance to keep your systems running smoothly.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2">
                            <div className="card card-body arj_card" data-aos="zoom-out">
                                <div className="div">
                                    <h5 className='text-center' data-aos="flip-left">Cybersecurity Focus
                                    </h5>
                                </div>
                                <p data-aos="fade-up">We implement advanced security measures to protect your data and systems from evolving cyber threats.
                                </p>
                            </div>
                        </div>

                        <div className=" col-md-4 col-6 mt-2">
                            <div className="card card-body arj_card" data-aos="zoom-in">
                                <div className="div">
                                    <h5 className='text-center' data-aos="flip-left">Client-Centric Approach
                                    </h5>
                                </div>
                                <p data-aos="fade-up">We prioritize understanding your unique needs and goals, ensuring every solution exceeds your expectations.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2">
                            <div className="card card-body arj_card" data-aos="zoom-in">
                                <div className="div">
                                    <h5 className='text-center' data-aos="flip-left">Team Dedication
                                    </h5>
                                </div>
                                <p data-aos="fade-up">Our passionate and skilled professionals work tirelessly to ensure the success of every project, delivering results on time and with precision.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-2">
                            <div className="card card-body arj_card" data-aos="zoom-in">
                                <div className="div">
                                    <h5 className='text-center' data-aos="flip-left">Scalable Growth Solutions
                                    </h5>
                                </div>
                                <p data-aos="fade-up">We craft solutions designed to grow with your business, helping you stay competitive in a rapidly changing digital landscape.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*/testimonials*/}
            <section className="w3l-clients py-5" id="testimonials">
                <div className="container py-sm-5 py-2">
                    <div className="text-center mb-sm-5 mb-4" data-aos="fade-up">
                        <h6 className="w3l-title-small mb-2">Testimonials</h6>
                        <h3 className="w3l-title-main mb-2">What people say about us</h3>
                    </div>
                    <div className="w3-testimonial-grids align-items-center pt-md-4">
                        <div className="w3-testimonial-content-top">
                            <div id="owl-demo1" className="owl-carousel owl-theme" data-aos="fade-up">
                                <div className="item">
                                    <div className="testimonial-content">
                                        <div className="test-img">
                                            <img
                                                src="assets/img/fixa.png"
                                                className="img-fluid"
                                                alt="client-img"
                                            />
                                        </div>
                                        <div className="testimonial">
                                            <blockquote>
                                                <q>
                                                    "ARJ Technologies’ software has greatly improved our daily operations, making order management and returns seamless. The user-friendly interface simplifies staff training, and their support is always prompt and helpful.""
                                                </q>
                                            </blockquote>
                                            <div className="testi-des">
                                                <div className="peopl align-self">
                                                    <h3>FIXA MANS</h3>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonial-content">
                                        <div className="test-img">
                                            <img
                                                src="assets/img/utthan.png"
                                                className="img-fluid"
                                                alt="client-img"
                                            />
                                        </div>
                                        <div className="testimonial">
                                            <blockquote>
                                                <q>
                                                    "This software has transformed our coaching center's management, automating tasks that were once manual. The reporting feature is excellent, and real-time updates keep us in control. Its flexibility allows us to customize it to our needs."
                                                </q>
                                            </blockquote>
                                            <div className="testi-des">
                                                <div className="peopl align-self">
                                                    <h3>UTTHAN CAREER INSTITUTE </h3>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonial-content">
                                        <div className="test-img">
                                            <img
                                                src="assets/img/arj.png"
                                                className="img-fluid"
                                                alt="client-img"
                                            />
                                        </div>
                                        <div className="testimonial">
                                            <blockquote>
                                                <q>
                                                    "ARJ Technologies' school management software has digitized our operations, making everything from admissions to exam results seamless. The real-time updates and easy parent communication have boosted our efficiency, allowing us to focus more on students."
                                                </q>
                                            </blockquote>
                                            <div className="testi-des">
                                                <div className="peopl align-self">
                                                    <h3>ARJ INSTITUTE OF TECHNOLOGY</h3>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonial-content">
                                        <div className="test-img">
                                            <img
                                                src="assets/img/ubgot.png"
                                                className="img-fluid"
                                                alt="client-img"
                                            />
                                        </div>
                                        <div className="testimonial">
                                            <blockquote>
                                                <q>
                                                    "ARJ Technologies' school management software has been a game-changer. Its user-friendly interface makes managing student data, attendance, and fees effortless. Communication with parents has improved, and the platform is reliable and tailored to our needs."
                                                </q>
                                            </blockquote>
                                            <div className="testi-des">
                                                <div className="peopl align-self">
                                                    <h3>UBGOT ACADEMY</h3>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonial-content">
                                        <div className="test-img">
                                            <img
                                                src="assets/img/vps.jpg"
                                                className="img-fluid"
                                                alt="client-img"
                                                width={'100px'}
                                            />
                                        </div>
                                        <div className="testimonial">
                                            <blockquote>
                                                <q>
                                                    "Vrindavan Public School is highly satisfied with ARJ Technologies' Scai Software. It has simplified our administrative tasks, from student enrollment to grade management, improving efficiency and reducing errors. We highly recommend Scai Software for any educational institution."
                                                </q>
                                            </blockquote>
                                            <div className="testi-des">
                                                <div className="peopl align-self">
                                                    <h3>Vrindavan Public School</h3>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonial-content">
                                        <div className="test-img">
                                            <img
                                                src="assets/img/shivam.png"
                                                className="img-fluid"
                                                alt="client-img"
                                                width={'100px'}
                                            />
                                        </div>
                                        <div className="testimonial">
                                            <blockquote>
                                                <q>
                                                    "Shivam Finance is highly satisfied with ARJ Technologies' Finance Software. It has streamlined our financial processes, from accounting to reporting, improving efficiency and reducing errors. We highly recommend Scai Software for any finance business."
                                                </q>
                                            </blockquote>
                                            <div className="testi-des">
                                                <div className="peopl align-self">
                                                    <h3>Shivam Finance</h3>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* //testimonials */}
            {/* technology Section */}
            <section className="new_tech_sec pb-5">
                <div className="container">
                    <h1 className='text-center mb-5' data-aos="fade-up">Our Latest Technologies</h1>
                    <ul className="nav nav-tabs mb-5" id="myTab" role="tablist" data-aos="fade-up">
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active"
                                id="home-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#All"
                                type="button"
                                role="tab"
                                aria-controls="home-tab-pane"
                                aria-selected="true"
                            >
                                Custom
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="profile-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#App"
                                type="button"
                                role="tab"
                                aria-controls="profile-tab-pane"
                                aria-selected="false"
                            >
                                App Development
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="contact-tab-pane"
                                data-bs-toggle="tab"
                                data-bs-target="#Web"
                                type="button"
                                role="tab"
                                aria-controls="contact-tab-pane"
                                aria-selected="false"
                            >
                                Web Development
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="Cms-tab-pane"
                                data-bs-toggle="tab"
                                data-bs-target="#Cms"
                                type="button"
                                role="tab"
                                aria-controls="contact-tab-pane"
                                aria-selected="false"
                            >
                                CMS
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="contact-tab-pane"
                                data-bs-toggle="tab"
                                data-bs-target="#DataBase"
                                type="button"
                                role="tab"
                                aria-controls="contact-tab-pane"
                                aria-selected="false"
                            >
                                DataBase
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="contact-tab-pane"
                                data-bs-toggle="tab"
                                data-bs-target="#FrontEnd"
                                type="button"
                                role="tab"
                                aria-controls="contact-tab-pane"
                                aria-selected="false"
                            >
                                Frontend Development
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent" data-aos="fade-up">
                        <div
                            className="tab-pane fade show active"
                            id="All"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                            tabIndex={0}
                        >
                            <div className="row mt-3 mb-3">
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Swift.svg" alt="" width={65} />
                                        <p className='fw-bold'>Swift</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/SwiftUI.svg" alt="" width={65} />
                                        <p className='fw-bold'>Swift UI</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Kotlin.svg" alt="" width={65} />
                                        <p className='fw-bold'>Kotlin</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Java.svg" alt="" width={65} />
                                        <p className='fw-bold'>Java</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/React-native.svg" alt="" width={65} />
                                        <p className='fw-bold'>React Native</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/images/Flutter.svg" alt="" width={65} />
                                        <p className='fw-bold'>Flutter</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3 mb-3">
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Angular.svg" alt="" width={65} />
                                        <p className='fw-bold'>Angular</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/images/React.svg" alt="" width={65} />
                                        <p className='fw-bold'>React</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Javascript.svg" alt="" width={65} />
                                        <p className='fw-bold'>Javascript</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Bootstrap.svg" alt="" width={65} />
                                        <p className='fw-bold'>Bootstrap</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/CSS3.svg" alt="" width={65} />
                                        <p className='fw-bold'>CSS</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/HTML5.svg" alt="" width={65} />
                                        <p className='fw-bold'>HTML</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3 mb-3">
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/img/node-js.909x1024.png" alt="" width={55} />
                                        <p className='fw-bold'>NODE JS</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/img/python_1822899.png" alt="" width={65} />
                                        <p className='fw-bold'>PYTHON</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/img/js_5968292.png" alt="" width={65} />
                                        <p className='fw-bold'>JAVASCRIPT</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/img/php.png" alt="" width={65} />
                                        <p className='fw-bold'>PHP</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/img/laravel.995x1024.png" alt="" width={65} />
                                        <p className='fw-bold'>LARAVEL</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/images/MongoDB.svg" alt="" width={65} />
                                        <p className='fw-bold'>MongoDB</p>
                                    </div>
                                </div>

                            </div>
                            <div className="row mt-3 mb-3">
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/wordpress.svg" alt="" width={65} />
                                        <p className='fw-bold'>WordPress</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/images/Strapi.svg" alt="" width={65} />
                                        <p className='fw-bold'>Strap.io</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Sitecore.svg" alt="" width={65} />
                                        <p className='fw-bold'>Sitecore</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/webflow.svg" alt="" width={65} />
                                        <p className='fw-bold'>Webflow</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Umbraco.svg" alt="" width={65} />
                                        <p className='fw-bold'>Umbraco</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/MySQL.svg" alt="" width={65} />
                                        <p className='fw-bold'>MySQL</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="App"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                            tabIndex={0}
                        >
                            <div className="row mt-3 mb-3">
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Swift.svg" alt="" width={65} />
                                        <p className='fw-bold'>Swift</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/SwiftUI.svg" alt="" width={65} />
                                        <p className='fw-bold'>Swift UI</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Kotlin.svg" alt="" width={65} />
                                        <p className='fw-bold'>Kotlin</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Java.svg" alt="" width={65} />
                                        <p className='fw-bold'>Java</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/React-native.svg" alt="" width={65} />
                                        <p className='fw-bold'>React Native</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/images/Flutter.svg" alt="" width={65} />
                                        <p className='fw-bold'>Flutter</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div
                            className="tab-pane fade"
                            id="Web"
                            role="tabpanel"
                            aria-labelledby="contact-tab"
                            tabIndex={0}
                        >
                            <div className="row mt-3 mb-3">
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/img/node-js.909x1024.png" alt="" width={55} />
                                        <p className='fw-bold'>NODE JS</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/img/python_1822899.png" alt="" width={65} />
                                        <p className='fw-bold'>PYTHON</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/img/js_5968292.png" alt="" width={65} />
                                        <p className='fw-bold'>JAVASCRIPT</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/img/php.png" alt="" width={65} />
                                        <p className='fw-bold'>PHP</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/img/laravel.995x1024.png" alt="" width={65} />
                                        <p className='fw-bold'>LARAVEL</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="Cms"
                            role="tabpanel"
                            aria-labelledby="contact-tab"
                            tabIndex={0}
                        >
                            <div className="row mt-3 mb-3">
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/wordpress.svg" alt="" width={65} />
                                        <p className='fw-bold'>WordPress</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/images/Strapi.svg" alt="" width={65} />
                                        <p className='fw-bold'>Strap.io</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Sitecore.svg" alt="" width={65} />
                                        <p className='fw-bold'>Sitecore</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/webflow.svg" alt="" width={65} />
                                        <p className='fw-bold'>Webflow</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Umbraco.svg" alt="" width={65} />
                                        <p className='fw-bold'>Umbraco</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="DataBase"
                            role="tabpanel"
                            aria-labelledby="contact-tab"
                            tabIndex={0}
                        >
                            <div className="row mt-3 mb-3">
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/MySQL.svg" alt="" width={65} />
                                        <p className='fw-bold'>MySQL</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/images/MongoDB.svg" alt="" width={65} />
                                        <p className='fw-bold'>MongoDB</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/nosql.svg" alt="" width={65} />
                                        <p className='fw-bold'>NO SQL</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/oracle.png" alt="" width={65} />
                                        <p className='fw-bold'>Oracle</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="FrontEnd"
                            role="tabpanel"
                            aria-labelledby="contact-tab"
                            tabIndex={0}
                        >
                            <div className="row mt-3 mb-3">
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Angular.svg" alt="" width={65} />
                                        <p className='fw-bold'>Angular</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech" >
                                        <img src="assets/images/React.svg" alt="" width={65} />
                                        <p className='fw-bold'>React</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Javascript.svg" alt="" width={65} />
                                        <p className='fw-bold'>Javascript</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/Bootstrap.svg" alt="" width={65} />
                                        <p className='fw-bold'>Bootstrap</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/CSS3.svg" alt="" width={65} />
                                        <p className='fw-bold'>CSS</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 mt-2">
                                    <div className="card p-2 new_tech">
                                        <img src="assets/images/HTML5.svg" alt="" width={65} />
                                        <p className='fw-bold'>HTML</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            {/* //technology Section */}
            {/* call block */}
            <section className="w3l-call-to-action-6">
                <div className="container py-5">
                    <div className="d-lg-flex align-items-center justify-content-between">
                        <div className="left-content-call">
                            <span className="mb-2 text-italic" data-aos="fade-up">
                                "Empowering Your Vision with Innovative Solutions"
                            </span>
                            <h3 className="w3l-title-main text-white" data-aos="zoom-out">Enhancing your success</h3>
                        </div>
                        <div className="right-content-call mt-lg-0 mt-4">
                            <ul className="buttons">
                                <li className="phone-sec me-lg-4" data-aos="zoom-out">
                                    <i className="fas fa-phone-volume" />
                                    <a className="call-style-w3" href="tel:+917733095696">
                                        +917733095696
                                    </a>
                                </li>
                                <li data-aos="zoom-out">
                                    <Link
                                        to={'/contact'}
                                        className="btn btn-style transparent-btn mt-lg-0 mt-3"
                                    >
                                        Get Free Quote
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* //call block */}
            <Footer />
        </>

    )
}
